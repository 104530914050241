export function AboutBanner() {
    return <div className="lg:py-20 m-2">
        <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-5xl">
            <span className="block">Africa Indian Ocean</span>
            <span className="block text-accent">Regional Monitoring Agency</span>
        </h1>
        <p className="mt-3 text-md text-gray-300 sm:mt-5 ">
            RMAs have the safety oversight responsibilities necessary to support the
            implementation and continued safe use of RVSM, the following standards apply to
            any organization intending to fill the role of an RMA to establish and maintain
            a database of State RVSM approvals, monitor height-keeping performance,
            conduct safety and readiness assessments, monitor operator compliance with State
            approval requirements after RVSM implementation, and initiate necessary remedial
            actions if RVSM requirements are not met.

        </p>
    </div>
}
