import {useState} from "react";
import {MailIcon, PhoneIcon, GlobeIcon} from '@heroicons/react/outline'
import Breadcrumbs from "components/Breadcrumbs";
import PageTitle from "components/PageTitle";
import {faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons";
import {faLinkedinIn, faWhatsapp, faTwitter} from "@fortawesome/free-brands-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Circulars from "components/Circulars";
import {ApiConsumer} from "api/ApiConsumer";
import {APIRoutes} from "routes/api";


export default function Contact() {
    const [crumbs] = useState([{name: 'Contact Us', href: '/contact', current: false}])
    const [details, setDetails] = useState({})
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)

    function send() {
        setLoading(true)
        ApiConsumer.post(APIRoutes.API.EMAIL, details)
            .then(() => {
                setSent(true)
            })
            .catch(err => {
                setSent(false)
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    return <>
        <PageTitle title={'Contact Us'}/>
        <Breadcrumbs crumbs={crumbs}/>

        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 mb-0 mt-0">
                <div className="relative bg-white shadow-2xl">
                    <h2 className="sr-only">Contact us</h2>

                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        {/* Contact information */}
                        <div className="relative overflow-hidden py-10 px-6 bg-primary sm:px-10 xl:p-12">
                            <h3 className="text-lg font-medium text-white font-bold">Contact information</h3>
                            <p className="mt-6 text-base text-indigo-50 max-w-3xl">
                                15 Bonaero Dr<br/>
                                Kempton Park<br/>
                                Johannesburg<br/>
                                1619
                            </p>
                            <dl className="mt-8 space-y-6">
                                <dt>
                                    <span className="sr-only">Phone number</span>
                                </dt>
                                <dd className="flex text-base text-indigo-50">
                                    <PhoneIcon className="flex-shrink-0 w-6 h-6 text-indigo-200" aria-hidden="true"/>
                                    <span className="ml-3 hover:text-accent">
                                        <a href='tel:+27119286506' target='_blank'>+27 11 928 6506</a>
                                    </span>
                                </dd>
                                <dt>
                                    <span className="sr-only">Whatsapp</span>
                                </dt>
                                <dd className="flex text-base text-indigo-50">
                                    <FontAwesomeIcon icon={faWhatsapp} className="flex-shrink-0 w-6 h-6 text-indigo-200"
                                                     aria-hidden="true"/>
                                    <span className="ml-3 hover:text-accent">
                                        <a href='https://api.whatsapp.com/send?phone=+27636911195' target='_blank'>+27 63 691 2295 </a>
                                    </span>
                                </dd>
                                <dt>
                                    <span className="sr-only">Email</span>
                                </dt>
                                <dd className="flex text-base text-indigo-50">
                                    <MailIcon className="flex-shrink-0 w-6 h-6 text-indigo-200" aria-hidden="true"/>
                                    <span className="ml-3 hover:text-accent">
                                        <a href='mailto:afirma@atns.co.za' target='_blank'>afirma@atns.co.za</a>
                                    </span>
                                </dd>

                                <dt>
                                    <span className="sr-only">Phone Number</span>
                                </dt>
                                <dd className="flex text-base text-indigo-50">
                                    <FontAwesomeIcon icon={faLinkedinIn}
                                                     className="flex-shrink-0 w-6 h-6 text-indigo-200"
                                                     aria-hidden="true"/>

                                    <span className="ml-3 hover:text-accent">
                                        <a href='https://www.linkedin.com/in/afi-regional-monitoring-agency-137713170/'
                                           target='_blank'>@afi-regional-monitoring-agency</a>
                                    </span>
                                </dd>


                                <dt>
                                    <span className="sr-only">Twitter</span>
                                </dt>
                                <dd className="flex text-base text-indigo-50">
                                    <FontAwesomeIcon icon={faTwitter} className="flex-shrink-0 w-6 h-6 text-indigo-200"
                                                     aria-hidden="true"/>
                                    <span className="ml-3 hover:text-accent">
                                        <a href='https://twitter.com/AFI_RMA' target='_blank'>@AFI_RMA</a>
                                    </span>
                                </dd>
                            </dl>
                        </div>

                        {/* Contact form */}
                        {
                            sent ?
                                <h5 className='tx-success mt-5 mb-5 text-center col-span-2 pt-5'>
                                    <strong>Submitted Successfully.</strong><br/>
                                    Thank You. Our friendly staff will review your Enquiry/Request and get back to you.
                                </h5> :
                                <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                                    <h3 className="text-lg font-bold text-gray-900">Send us a message</h3>
                                    <form onSubmit={event => {
                                        event.preventDefault()
                                        send()
                                    }} className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                        <div>
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium text-gray-900">
                                                First name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name="first-name"
                                                    id="first-name"
                                                    disabled={loading}
                                                    required
                                                    autoComplete="given-name"
                                                    className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                    onChange={event => setDetails(prevState => ({
                                                        ...prevState,
                                                        ['first_name']: event.target.value
                                                    }))}
                                                    value={details?.first_name}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="last-name"
                                                   className="block text-sm font-medium text-gray-900">
                                                Last name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name="last-name"
                                                    id="last-name"
                                                    autoComplete="family-name"
                                                    disabled={loading}
                                                    required
                                                    className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 border-gray-300 rounded-md border-indigo-200 border-2"
                                                    onChange={event => setDetails(prevState => ({
                                                        ...prevState,
                                                        ['last_name']: event.target.value
                                                    }))}
                                                    value={details?.last_name}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                                                Email
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    disabled={loading}
                                                    required
                                                    className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 border-gray-300 rounded-md border-indigo-200 border-2"
                                                    onChange={event => setDetails(prevState => ({
                                                        ...prevState,
                                                        ['email']: event.target.value
                                                    }))}
                                                    value={details?.email}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex justify-between">
                                                <label htmlFor="phone"
                                                       className="block text-sm font-medium text-gray-900">
                                                    Phone
                                                </label>
                                                <span id="phone-optional" className="text-sm text-gray-500">
                      Optional
                    </span>
                                            </div>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    id="phone"
                                                    autoComplete="tel"
                                                    disabled={loading}
                                                    required
                                                    className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 border-gray-300 rounded-md border-indigo-200 border-2"
                                                    aria-describedby="phone-optional"
                                                    onChange={event => setDetails(prevState => ({
                                                        ...prevState,
                                                        ['phone']: event.target.value
                                                    }))}
                                                    value={details?.phone}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="subject"
                                                   className="block text-sm font-medium text-gray-900">
                                                Subject
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    disabled={loading}
                                                    required
                                                    className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                    onChange={event => setDetails(prevState => ({
                                                        ...prevState,
                                                        ['subject']: event.target.value
                                                    }))}
                                                    value={details?.subject}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <div className="flex justify-between">
                                                <label htmlFor="message"
                                                       className="block text-sm font-medium text-gray-900">
                                                    Message
                                                </label>
                                                <span id="message-max" className="text-sm text-gray-500">
                      Max. 500 characters
                    </span>
                                            </div>
                                            <div className="mt-1">
                    <textarea
                        id="message"
                        name="message"
                        disabled={loading}
                        rows={4}
                        required
                        className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                        aria-describedby="message-max"
                        defaultValue={''}
                        onChange={event => setDetails(prevState => ({
                            ...prevState,
                            ['message']: event.target.value
                        }))}
                        value={details?.message}
                    />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2 sm:flex sm:justify-end">
                                            <button
                                                type="submit" disabled={loading}
                                                className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                            >
                                                Submit {loading ? <GlobeIcon
                                                    className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                                <FontAwesomeIcon className='ml-2 pt-1 animate-bounce'
                                                                 icon={faArrowAltCircleRight}/>}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.5278990606676!2d28.251226815612075!3d-26.114325366668854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9515ab22eec9c3%3A0xb35d6d95c8aabade!2s15%20Bonaero%20Dr%2C%20Bonaero%20Park%2C%20Kempton%20Park%2C%201622!5e0!3m2!1sen!2sza!4v1648576026719!5m2!1sen!2sza"
            width="100%" height="650" style={{border: "0", marginTop: "-200px"}} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"/>
        <Circulars/>
    </>
}
