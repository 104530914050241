import ReactGA from 'react-ga';
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {GOOGLE_ID} from "config";

export default function GoogleAnalytics() {
    const { pathname } = useLocation();

    useEffect(() => {
        ReactGA.initialize(GOOGLE_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [pathname]);
    return <></>
}
