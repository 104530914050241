import ReactMarkdown from "react-markdown";
import Loader from "components/Loader";
import {useEffect} from "react";

export function PageContent({content, loading = true, extras = null}) {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [content])
    return <>
        <div className='border-2 pb-6 rounded'>
            <div className="relative px-4 sm:px-6">
                <article className="mt-6 prose max-w-none text-gray-500 space-y-5">
                    { !loading ?<ReactMarkdown>{content}</ReactMarkdown> : null }
                    {
                        loading ? <Loader/> : null
                    }
                </article>
            </div>
        </div>
        {(extras)}
    </>
}
