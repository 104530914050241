import icon from "assets/images/ARMA_Logo3-22.svg";
import Loader from "components/Loader";

export default function SideMenu({menu = [], setTitle, title, setCrumbs, setContent, loading = true}) {
    function compare(a, b) {
        return a.order - b.order
    }
    return <>
        <aside className="w-100" aria-label="Sidebar">
            <div className="px-3 py-4 overflow-y-auto rounded bg-primary">
                <ul className="space-y-2">
                    {
                        menu.sort(compare).map((item, index) => {
                            console.log(item.attributes?.title ?? item.title)
                            return <li key={index}>
                                <div onClick={() => {
                                    setContent(item.id)
                                    setCrumbs(item.attributes?.title ?? item.title)
                                    setTitle(item.attributes?.title ?? item.title)
                                }}
                                     className={`cursor-pointer flex items-center p-2 ${title === item.attributes?.title ?? item.title ? 'bg-gray-100 text-black ' : 'font-normal text-white'} text-base rounded-lg hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-black`}>
                                    <img className='pt-2' src={icon} alt='ARMA icon' width='30' height='40'/>
                                    <span
                                        className={`ml-3 hover:text-black ${title === item.attributes?.title ?? item.title ? 'bg-gray-100 text-black font-bold' : 'font-normal text-white'}`}>{item.attributes?.title ?? item.title}</span>
                                </div>
                            </li>
                        })
                    }
                    {
                        loading ? <Loader light={true}/> : null
                    }
                </ul>
            </div>
        </aside>
    </>
}
