import Home from "pages/home";
import About from "pages/about";
import Services from "pages/services";
import Resources from "pages/resources";
import Contact from "pages/contact";
import FAQ from "pages/faq";
import Media from "pages/media";

const WEBSITE_ROUTES = [
    {
        name: 'Home',
        link: '/',
        page: Home,
        exact: true
    },
    {
        name: 'Services',
        link: '/services',
        page: Services,
        exact: false
    },
    {
        name: 'Documents',
        link: '/resources',
        page: Resources,
        exact: false
    },
    {
        name: 'Media',
        link: '/media',
        page: Media,
        exact: false
    },
    {
        name: 'FAQs',
        link: '/faq',
        page: FAQ,
        exact: false
    },
    {
        name: 'About Us',
        link: '/about',
        page: About
    },
    {
        name: 'Contact Us',
        link: '/contact',
        page: Contact,
        exact: false
    }
]
export default WEBSITE_ROUTES
