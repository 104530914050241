import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons";

export default function CTA() {
    return (
        <div className="bg-gray-200">
            <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                    <span className="block">Height Monitoring</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-indigo-800">
                    Monitoring an aircraft’s height keeping performance is an important part of the RVSM Approval process.
                </p>
                <NavLink
                    to="/services"
                    className="border-2 border-blue-500 mt-8 w-full inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-800 hover:text-white sm:w-auto"
                >
                    Visit Height Monitoring {' '} <FontAwesomeIcon className='ml-2 pt-1' icon={faArrowAltCircleRight}/>
                </NavLink>
            </div>
        </div>
    )
}
