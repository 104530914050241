import {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export function TeamDetail({detail, visible, setVisible, image, name}) {
    return <>
        <Transition.Root show={visible} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto pt-20" onClose={setVisible}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-20 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-lg sm:w-full sm:p-6">
                            <div>
                                {
                                    image === null ? null :
                                        <div
                                            className="mx-auto flex items-center justify-center h-24 w-24 rounded-full">
                                            <img className="bg-gray-100 rounded-lg" src={image} alt={''}/>
                                            <div className="h-32 bg-gray-100 rounded-lg" style={{
                                                backgroundImage: `url("${image}")`,
                                                backgroundRepeat: 'no-repeat', backgroundSize: "cover"
                                            }}>
                                            </div>
                                        </div>
                                }
                                <div className="mt-3 sm:mt-5">
                                    <Dialog.Title as="h3"
                                                  className="text-lg leading-6 font-medium text-gray-900 text-center">
                                        {name}
                                    </Dialog.Title>
                                    <div className="mt-2 prose max-w-none">
                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{detail}</ReactMarkdown>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6">
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                    onClick={() => setVisible(false)}
                                >
                                    Go back
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}
