import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons";
import logo from 'assets/images/ARMA_Logo3-22.svg'
import {NavLink} from "react-router-dom";

export default function Circulars() {
    return (
        <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <svg
                    className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    role="img"
                    aria-labelledby="svg-workcation"
                >
                    <defs>
                        <pattern
                            id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
                </svg>

                <div className="relative">
                    <div className='text-center'>
                        <h2 className='text-2xl font-bold'><FontAwesomeIcon icon={faFilePdf} className='text-red-500'/> Documents</h2>
                    </div>

                    <blockquote className="mt-10">
                        <div className="max-w-3xl mx-auto text-center text-md leading-9 font-medium text-gray-900">
                            <p>
                                ARMA interacts with the ICAO ESAF office in Nairobi Kenya for all ICAO
                                type administration and produces RVSM outputs to satisfy ICAO for AFI, we also reports
                                certain activities to ICAO Montreal and belongs to the Global RMA committee which
                                meets on an annual basis to harmonise the working principles of the global RMAs.
                            </p>
                        </div>
                        <div className='text-center'>
                            <NavLink
                                to="/resources"
                                className="bg-gray-400 mt-8 w-full inline-flex items-center justify-center px-5 py-3 border-2 border-white text-base font-medium rounded-md text-white hover:bg-gray-800 sm:w-auto"
                            >
                                Go to documents {' '} <FontAwesomeIcon className='ml-2 pt-1' icon={faArrowAltCircleRight}/>
                            </NavLink>
                        </div>
                    </blockquote>

                </div>
            </div>
        </section>
    )
}
