import PageTitle from "components/PageTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons";
import Breadcrumbs from "components/Breadcrumbs";
import {useState} from "react";
import {NavLink} from "react-router-dom";
import {ApiConsumer} from "api/ApiConsumer";
import {APIRoutes} from "routes/api";
import {GlobeIcon} from "@heroicons/react/outline";

export function FormF4() {
    const [crumbs] = useState([{name: 'Resources', href: '/resources', current: true},
        {name: 'Forms', href: '/resources', current: true}, {
            name: 'RVSM FORM 4 - FPL TFC Data',
            href: '/resources',
            current: true
        }])
    const [details, setDetails] = useState({'subject': 'RVSM FORM 4 - FPL TFC Data'})
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)

    function send() {
        setLoading(true)
        ApiConsumer.post(APIRoutes.API.FORM, details)
            .then(() => {
                setSent(true)
            })
            .catch(err => {
                setSent(false)
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    return <>
        <PageTitle title={'RVSM FORM 4 - FPL TFC Data'}/>
        <Breadcrumbs crumbs={crumbs}/>
        <div
            className="mt-8 max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6 mb-10">
            <div className="space-y-5 lg:col-start-1 lg:col-span-4  mb-4">

                <div className='border-2 pb-6 rounded mt-5 pt-5'>
                    <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="mt-6 prose max-w-none text-gray-500 mx-auto space-y-5">
                            <h2 className='text-secondary text-center text-2xl font-extrabold'>AIR REGIONAL MONITORING
                                AGENCY (ARMA)</h2>
                            <p className='text-center px-5'>Aircraft Traffic Data Flow - FORM 4 </p>
                            <div className="grid grid-cols-1 lg:grid-cols-3">
                                {/* Contact form */}
                                {
                                    sent ?
                                        <h5 className='tx-success mt-5 mb-5 text-center col-span-2 pt-5'>
                                            <strong>Submitted Successfully.</strong><br/>
                                            Thank You. Our friendly staff will review your Enquiry/Request and get back
                                            to you.
                                        </h5> :
                                        <div className="sm:px-10 lg:col-span-2 xl:p-2">
                                            <form method="POST" onSubmit={event => {
                                                event.preventDefault()
                                                send()
                                            }}
                                                  className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                                <div>
                                                    <label htmlFor="date"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Date
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="date"
                                                            name="date"
                                                            id="date"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['date']: event.target.value
                                                            }))}
                                                            value={details?.date}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="route"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Route
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="route"
                                                            id="route"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['route']: event.target.value
                                                            }))}
                                                            value={details?.route}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="flight_number"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Flight Number
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="flight_number"
                                                            id="flight_number"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['flight_number']: event.target.value
                                                            }))}
                                                            value={details?.flight_number}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="type"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Type
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="type"
                                                            id="type"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['type']: event.target.value
                                                            }))}
                                                            value={details?.type}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="icao_code"
                                                           className="block text-sm font-medium text-gray-900">
                                                        ICAO Code
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="icao_code"
                                                            id="icao_code"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['icao_code']: event.target.value
                                                            }))}
                                                            value={details?.icao_code}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="from"
                                                           className="block text-sm font-medium text-gray-900">
                                                        From
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="from"
                                                            id="from"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['from']: event.target.value
                                                            }))}
                                                            value={details?.from}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="to"
                                                           className="block text-sm font-medium text-gray-900">
                                                        To
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="to"
                                                            id="to"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['to']: event.target.value
                                                            }))}
                                                            value={details?.to}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="eqpt"
                                                           className="block text-sm font-medium text-gray-900">
                                                        EQPT
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="eqpt"
                                                            id="eqpt"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['eqpt']: event.target.value
                                                            }))}
                                                            value={details?.eqpt}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="relative col-span-2">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                <span className="bg-white px-2 text-gray-500">

                                                </span>
                                                    </div>
                                                </div>


                                                <div>
                                                    <label htmlFor="position_1"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Position
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="position_1"
                                                            id="position_1"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['position_1']: event.target.value
                                                            }))}
                                                            value={details?.position_1}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="time_1"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Time
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="time_1"
                                                            id="time_1"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['time_1']: event.target.value
                                                            }))}
                                                            value={details?.time_1}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="flight_level_1"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Flight Level
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="flight_level_1"
                                                            id="flight_level_1"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['flight_level_1']: event.target.value
                                                            }))}
                                                            value={details?.flight_level_1}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="relative col-span-2">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                <span className="bg-white px-2 text-gray-500">

                                                </span>
                                                    </div>
                                                </div>


                                                <div>
                                                    <label htmlFor="position_2"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Position
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="position_2"
                                                            id="position_2"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['position_2']: event.target.value
                                                            }))}
                                                            value={details?.position_2}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="time_2"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Time
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="time_2"
                                                            id="time_1"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['time_2']: event.target.value
                                                            }))}
                                                            value={details?.time_2}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="flight_level_2"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Flight Level
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="flight_level_2"
                                                            id="flight_level_2"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['flight_level_2']: event.target.value
                                                            }))}
                                                            value={details?.flight_level_2}
                                                        />
                                                    </div>
                                                </div>

                                                {/*3*/}
                                                <div className="relative col-span-2">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                <span className="bg-white px-2 text-gray-500">

                                                </span>
                                                    </div>
                                                </div>


                                                <div>
                                                    <label htmlFor="position_3"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Position
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="position_3"
                                                            id="position_3"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['position_3']: event.target.value
                                                            }))}
                                                            value={details?.position_3}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="time_3"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Time
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="time_3"
                                                            id="time_1"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['time_3']: event.target.value
                                                            }))}
                                                            value={details?.time_3}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="flight_level_3"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Flight Level
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="flight_level_3"
                                                            id="flight_level_3"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['flight_level_3']: event.target.value
                                                            }))}
                                                            value={details?.flight_level_3}
                                                        />
                                                    </div>
                                                </div>

                                                {/*4*/}
                                                <div className="relative col-span-2">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                <span className="bg-white px-2 text-gray-500">

                                                </span>
                                                    </div>
                                                </div>


                                                <div>
                                                    <label htmlFor="position_4"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Position
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="position_4"
                                                            id="position_4"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['position_4']: event.target.value
                                                            }))}
                                                            value={details?.position_4}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="time_4"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Time
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="time_4"
                                                            id="time_1"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['time_4']: event.target.value
                                                            }))}
                                                            value={details?.time_4}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="flight_level_4"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Flight Level
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="flight_level_4"
                                                            id="flight_level_4"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['flight_level_4']: event.target.value
                                                            }))}
                                                            value={details?.flight_level_4}
                                                        />
                                                    </div>
                                                </div>

                                                {/*5*/}
                                                <div className="relative col-span-2">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                <span className="bg-white px-2 text-gray-500">

                                                </span>
                                                    </div>
                                                </div>


                                                <div>
                                                    <label htmlFor="position_5"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Position
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="position_5"
                                                            id="position_5"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['position_5']: event.target.value
                                                            }))}
                                                            value={details?.position_5}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="time_2"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Time
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="time_5"
                                                            id="time_1"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['time_5']: event.target.value
                                                            }))}
                                                            value={details?.time_5}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="flight_level_5"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Flight Level
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="flight_level_5"
                                                            id="flight_level_5"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['flight_level_5']: event.target.value
                                                            }))}
                                                            value={details?.flight_level_5}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-span-2 x-span-2'>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Email
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['email']: event.target.value
                                                            }))}
                                                            value={details?.email}
                                                        />
                                                    </div>
                                                </div>


                                                <div/>
                                                <div className="sm:col-span-2 sm:flex sm:justify-end">
                                                    <button
                                                        type="submit"
                                                        className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                                    >
                                                        Submit {loading ? <GlobeIcon
                                                            className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                                        <FontAwesomeIcon className='ml-2 pt-1 animate-bounce'
                                                                         icon={faArrowAltCircleRight}/>}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                }

                                {/* Contact information */}
                                <div className="relative overflow-hidden py-10 px-6 bg-primary sm:px-10 xl:p-12">
                                    <h3 className="text-lg font-medium text-white font-bold">Notes</h3>
                                    <hr className='bg-primary text-secondary'/>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Important Note</strong>: Please include
                                        information on all aircraft
                                        overflying the airspace within thr flight leve band F280 -F410. </p>
                                    <hr className='mt-10 pt-5'/>
                                    <p className='text-accent'>
                                        For more information, please feel free to send us a message.
                                    </p>
                                    <NavLink to={'/contact'}>
                                        <p className='mt-3 font-bold text-accent'>
                                            Contact Us
                                            <FontAwesomeIcon className='animate-bounce ml-2 pt-1'
                                                             icon={faArrowAltCircleRight}/>
                                        </p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
