import {useEffect, useState} from "react";
import PageTitle from "components/PageTitle";
import Breadcrumbs from "components/Breadcrumbs";
import Loader from "components/Loader";
import {MediaTable} from "components/Media/table";
import {ApiConsumer} from "api/ApiConsumer";
import {CMS_Routes} from "routes/cms";


export default function Media() {
    const [media, setMedia] = useState([])
    const [crumbs] = useState([{name: 'News & Media', href: '/media', current: true}])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        console.log(CMS_Routes.PAGES.MEDIA)
        ApiConsumer.get(CMS_Routes.PAGES.MEDIA)
            .then(res => {
                setMedia(res.data.data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }, [])

    return <>
        <PageTitle title={'News & Media'}/>
        <Breadcrumbs crumbs={crumbs}/>
        <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                {
                    loading ? <Loader/> : null
                }
                {!loading ? <div
                    className="max-w-full mx-auto mb-10">
                    <div className="bg-white">
                        <div
                            className="max-w-7xl mx-auto px-4 divide-y-2 divide-gray-200 pb-12 sm:px-6 lg:px-8 card border-2 rounded">
                            <MediaTable media={media}/>
                        </div>
                    </div>
                </div> : null}
            </div>
        </div>
    </>
}
