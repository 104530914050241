import {ApiConsumer} from "api/ApiConsumer";
import {CMS_Routes} from "routes/cms";
import * as Sentry from "@sentry/react";
import qs from "qs";

export const useContentHook = () => {
    function loadSideMenu(contentLink, baseCrumb = [], setSideMenu = {}, setSideLoading = {}, filter_id='') {
        ApiConsumer.get(`${contentLink}`)
            .then(res => {
                setSideMenu(baseCrumb.concat(res.data.data))
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => setSideLoading(false))
    }

    function loadBreadCrumbs(base, title, setCrumbs) {
        setCrumbs(title !== base[0].name ? base.concat([{name: title, href: '/about', current: false}]) : base)
    }

    function pageLink(title, baseTitle, baseLink, link) {
        if (title === '')
            return baseLink
        if (title === baseTitle)
            return baseLink
        return CMS_Routes.CONTENT.PAGE_CONTENT(link)
    }

    function menuLink(title, baseTitle, baseLink, link) {
        if (title === '')
            return baseLink
        if (title === baseTitle)
            return baseLink
        return CMS_Routes.CONTENT.PAGE_MENU(link)
    }

    function loadContent(baseTitle, title, baseLink, link, setContent, setTitle, setContentLoading, loadSideMenu) {
        setContentLoading(true)

        ApiConsumer.get(pageLink(title, baseTitle, baseLink, link))
            .then(res => {
                setContent(Array.isArray(res.data.data.attributes) ? res.data.data.attributes[0] : res.data.data.attributes)
                setTitle(Array.isArray(res.data.data.attributes) ? res.data.data.attributes[0].title : res.data.data.attributes.title)
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => {
                loadSideMenu()
                setContentLoading(false)
            })
    }

    return {loadContent, loadSideMenu, loadBreadCrumbs, pageLink, menuLink}
}
