import background from 'assets/images/title_image.svg'

export default function PageTitle({title}) {
    return <>
        <div className="bg-white mt-14" style={{
            backgroundImage: `url('${background}')`,
            backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "240px"
        }}>
            <div className="max-w-7xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:px-8 lg:flex lg:justify-between">
                <div className="max-w-xl pt-20">
                    <h2 className={`text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight ${title.length < 20 ? 'lg:text-6xl' : 'lg:text-5xl'}`}>
                        {title}
                    </h2>
                </div>
            </div>
        </div>
    </>
}
