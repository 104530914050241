const axios = require("axios");
export const ApiConsumer = axios.create()

ApiConsumer.interceptors.request.use(request => {
        return request;
    }, error => {
        console.log(error)
        return Promise.reject(error);
    }
);
