import {useEffect, useState} from "react";
import logo from 'assets/images/ARMA_Logo3-22.svg'
import {ApiConsumer} from "api/ApiConsumer";
import {CMS_Routes} from "routes/cms";
import Loader from "components/Loader";
import {TeamDetail} from "components/Team/detail";

export default function Team() {
    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(true)
    const [detail, setDetail] = useState('')
    const [image, setImage] = useState('')
    const [name, setName] = useState('')
    const [visible, setVisible] = useState(false)

    function compare(a, b) {
        return a?.attributes?.order - b?.attributes?.order
    }

    useEffect(() => {
        console.log(CMS_Routes.TEAM.MEMBERS)
        ApiConsumer.get(CMS_Routes.TEAM.MEMBERS)
            .then(res => {
                console.log(res.data.data)
                setMembers(res.data.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    return <>
        <h3 className='text-2xl font-bold'>Our Team</h3>
        {
            loading ? <Loader/> : null
        }
        <div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-4 w-full justify-between">
            <TeamDetail name={name} detail={detail} visible={visible} setVisible={setVisible} image={image}/>
            {
                !loading ? members.sort(compare).map(member => {
                    return <div key={member.id}
                                className="relative p-4 w-full bg-white rounded-lg overflow-hidden shadow-2xl hover:shadow-md cursor-pointer hover:border-2 hover:border-blue-500 border-2 border-transparent"
                                style={{minHeight: "160px"}}
                                onClick={() => {
                                    setImage(`https://cms.arma.africa${member.attributes.picture.data.attributes.url}`)
                                    setName(`${member?.attributes?.full_name} - ${member.attributes.title}`)
                                    setDetail(member?.attributes?.bio)
                                    setVisible(true)
                                }}>
                        <div>
                            <div className="absolute top-0 right-0 mt-2 mr-2 p-4 z-10 flex justify-between">
                                <div
                                    className="inline-flex items-center justify-center w-8 h-8 p-2 rounded-full bg-white shadow-sm">
                                    <img src={logo} alt={''}/>
                                </div>
                            </div>
                            <div className="relative block h-full">
                                <div className="h-32 bg-gray-100 rounded-lg" style={{
                                    backgroundImage: `url("https://cms.arma.africa${member.attributes.picture.data.attributes.url}")`,
                                    backgroundRepeat: 'no-repeat', backgroundSize: "cover"
                                }}>
                                </div>
                            </div>
                        </div>
                        <h2 className="mt-2 text-gray-800 text-sm font-semibold line-clamp-1">
                            {member.attributes.full_name}
                        </h2>
                        <p className="mt-2 text-gray-800 text-sm">{member.attributes.title}</p>
                    </div>
                }) : null
            }
        </div>
    </>
}
