import React from "react";
import {BrowserRouter as Router, Switch, Route, useHistory} from "react-router-dom";
import GoogleAnalytics from "components/GoogleAnalytics";
import PageView from "components/PageView";
import NavBar from "components/NavBar";
import Footer from "components/Footer";
import WEBSITE_ROUTES from "routes/website";
import NotFound from "pages/404";
import FORM_ROUTES from "routes/forms";

export default function App() {
    let history = useHistory();
    return <>
        <Router history={history}>
            <GoogleAnalytics/>
            <PageView/>
            <NavBar/>
            <Switch>
                {
                    WEBSITE_ROUTES.concat(FORM_ROUTES).map((route, index) => {
                        return <Route key={index} exact path={route.link} component={route.page}/>
                    })
                }
                <Route component={NotFound}/>
            </Switch>
            <Footer/>
        </Router>
    </>
}
