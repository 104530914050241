import Slider from "react-slick";
import image from "assets/images/banner-image.svg"
import {AboutBanner} from "components/Banner/about";
import {VideoBanner} from "components/Banner/video";


export default function Banner() {
    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        accessibility: true,
        autoplaySpeed: 8000,
        dotsClass: 'slick-dots',
        centerPadding: '70px',
        fade: true,
        pauseOnDotsHover: true,
        pauseOnHover: true,
        swipe: true,
        swipeToSlide: true
    };

    return (
        <div className="relative overflow-hidden mt-16">
            <main>
                <div className="pt-10 bg-primary sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                            <Slider
                                className="sm:max-w-2xl sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center" {...settings}>
                                <AboutBanner/>
                                <VideoBanner/>
                            </Slider>
                            <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0">
                                    <img
                                        className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none animate__animated animate__backInRight"
                                        src={image}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
