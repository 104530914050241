import {FormF3} from "components/Forms/FormF3";
import {FormF2} from "components/Forms/FormF2";
import {FormF1} from "components/Forms/FormF1";
import {FormF4} from "components/Forms/FormF4";

const FORM_ROUTES = [
    {
        name: 'F1',
        link: '/resources/forms/f1',
        page: FormF1,
        exact: true
    },
    {
        name: 'F2',
        link: '/resources/forms/f2',
        page: FormF2,
        exact: true
    },
    {
        name: 'F3',
        link: '/resources/forms/f3',
        page: FormF3,
        exact: true
    },
    {
        name: 'F4',
        link: '/resources/forms/f4',
        page: FormF4,
        exact: true
    },
]
export default FORM_ROUTES
