import WEBSITE_ROUTES from "routes/website";
import {NavLink} from "react-router-dom";
import logo from 'assets/images/ARMA_Light Wordmark.svg'

const navigation = {
    solutions: [
        { name: 'Height Monitoring', href: '/services' },
        { name: 'RVSM', href: '/services' },
        { name: 'MMR', href: '/services' },
        { name: 'PBCS', href: '/services' },
    ],
    support: [
        { name: 'Forms', href: '/resources' },
        { name: 'Applications', href: '/resources' }
    ],
    legal: [
        { name: 'Privacy', href: '#' },
        { name: 'Terms', href: '#' },
    ]
}

export default function Footer() {
    return (
        <footer className="bg-primary" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="space-y-8 xl:col-span-1">
                        <img
                            className="h-10"
                            src={logo}
                            alt="Company name"
                        />
                        <p className="text-gray-500 text-base">
                            ICAO/ARMA has the responsibilities, among others, to monitor aircraft height-keeping performance and the occurrence of large height deviations, and to conduct safety assessments for its affiliated ICAO contracting States and FIRs.
                        </p>
                    </div>
                    <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold text-white tracking-wider uppercase">Services</h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {navigation.solutions.map((item) => (
                                        <li key={item.name}>
                                            <NavLink to={item.href} className="text-base text-gray-500 hover:text-white">
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-white tracking-wider uppercase">Resources</h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {navigation.support.map((item) => (
                                        <li key={item.name}>
                                            <NavLink to={item.href} className="text-base text-gray-500 hover:text-white">
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold text-white tracking-wider uppercase">Company</h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {WEBSITE_ROUTES.slice(0,4).map((route) => (
                                        <li key={route.name}>
                                            <NavLink activeClassName={'text-white'} exact={route.exact} to={route.link} className="text-base text-gray-500 hover:text-white">
                                                {route.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-white tracking-wider uppercase">Legal</h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {navigation.legal.map((item) => (
                                        <li key={item.name}>
                                            <NavLink to={item.href} className="text-base text-gray-500 hover:text-white">
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 border-t border-gray-200 pt-8">
                    <p className="text-base text-white xl:text-center">&copy; {new Date().getFullYear()} Africa Indian Ocean Regional Monitoring Agency. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}
