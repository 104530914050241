import {DocumentIcon, PencilAltIcon, ArrowCircleDownIcon, ArrowCircleRightIcon} from '@heroicons/react/outline'
import {useEffect, useState} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import {CMS_Routes} from "routes/cms";
import Loader from "components/Loader";

export default function ResourceGrid({type = '', title = ''}) {
    const [loading, setLoading] = useState(true)
    const [resources, setResources] = useState([])

    function getLink(resource) {
        if (resource?.attributes?.type === 'pdf') {
            return `https://cms.arma.africa${resource?.attributes?.file?.data.attributes.url}`
        }
        if (resource?.attributes?.type === 'form') {
            return resource?.attributes?.link
        }
        return ''
    }

    useEffect(() => {
        ApiConsumer.get(CMS_Routes.RESOURCES.BY_TYPE(type))
            .then(res => {
                setResources(res.data.data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }, [type])
    return <>
        <div className="relative mb-3">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"/>
            </div>
            <div className="relative flex justify-start">
                <span className="pr-3 bg-white text-lg font-medium text-gray-900">{title}</span>
            </div>
        </div>
        {
            loading ? <Loader/> :
                <ul key={type} role="list"
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-6">
                    {
                        resources.map(resource => {
                            return <a key={resource.attributes.id} href={getLink(resource)} target='_blank'>
                                <li
                                    key={resource.attributes.id}
                                    className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow-lg divide-y divide-gray-200 h-full"
                                >
                                    <div className="flex-1 flex flex-col p-8">
                                        <div
                                            className={`w-32 h-32 flex-shrink-0 mx-auto rounded ${resource.attributes.type === 'pdf' ? 'bg-red-600' : 'bg-primary'}`}>
                                            {
                                                resource.attributes.type === 'pdf' ?
                                                    <DocumentIcon
                                                        className={'text-white h-28 w-28 mx-auto my-auto pt-5'}/> :
                                                    <PencilAltIcon
                                                        className={'text-white h-28 w-28 mx-auto my-auto pt-5'}/>
                                            }
                                        </div>
                                        <h3 className="mt-4 text-gray-900 text-md font-bold">{resource.attributes.name}</h3>
                                        <dl className="mt-1 flex-grow flex flex-col justify-between">
                                            <dd className="mt-2">
                                            <span
                                                className="px-2 py-1 text-green-800 text-xs font-medium cursor-pointer">
                                                <a key={resource.attributes.id} href={getLink(resource)}
                                                   target='_blank'>
                                              {
                                                  resource.attributes.type === 'pdf' ?
                                                      <ArrowCircleDownIcon
                                                          className={'h-10 w-10 text-red-600 mx-auto animate-bounce'}/> :
                                                      <ArrowCircleRightIcon
                                                          className={'h-10 w-10 text-blue-800 mx-auto animate-pulse'}/>}
                                                </a>
                                            </span>
                                            </dd>
                                        </dl>
                                    </div>
                                </li>
                            </a>
                        })
                    }
                </ul>
        }
    </>
}
