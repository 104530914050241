export default function PageLayout({content, side}) {
    return <>
        <div
            className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6 mb-10">
            <div className="space-y-6 lg:col-start-1 lg:col-span-4  mb-4">
                {content}
            </div>
            <div className="max-w-2xl mx-auto lg:col-start-5 lg:col-span-2 min-w-full sticky">
                {(side)}
            </div>
        </div>
    </>
}
