import Breadcrumbs from "components/Breadcrumbs";
import {useEffect, useState} from "react";
import PageTitle from "components/PageTitle";
import Team from "components/Team";
import SideMenu from "components/SideMenu";
import PageLayout from "components/PageLayout";
import {CMS_Routes} from "routes/cms";
import {PageContent} from "components/PageContent";
import {useContentHook} from "hooks/contentHook";


export default function About() {
    const [crumbs, setCrumbs] = useState([{name: 'About Us', href: '/about', current: true}])
    const [link, setLink] = useState('1')
    const [contentLoading, setContentLoading] = useState(true)
    const [sideLoading, setSideLoading] = useState(true)
    const [content, setContent] = useState('')
    const [title, setTitle] = useState('')
    const [sideMenu, setSideMenu] = useState([])

    const baseBreadCrumb = [{name: 'About Us', href: '/about', current: true}]

    let {loadSideMenu, loadContent, loadBreadCrumbs} = useContentHook()

    function breadCrumbs(title) {
        loadBreadCrumbs(baseBreadCrumb, title, setCrumbs)
    }

    function loadMenu() {
        loadSideMenu(CMS_Routes.CONTENT.PAGE_MENU(1), [{
            attributes: {
                title: 'About Us', id: ''
            }
        }], setSideMenu, setSideLoading)
    }

    useEffect(() => {
        setContentLoading(true)
        loadContent('About Us', title, CMS_Routes.PAGES.ABOUT, link, setContent, setTitle, setContentLoading, loadMenu)
    }, [link])

    return <>
        <PageTitle title={'About Us'}/>
        <Breadcrumbs crumbs={crumbs}/>
        <PageLayout
            content={<PageContent content={content?.content ?? ''} loading={contentLoading} extras={<Team/>}/>}
            side={<SideMenu menu={sideMenu} title={title} setTitle={setTitle}
                            setCrumbs={breadCrumbs} loading={sideLoading} setContent={setLink}/>}/>
    </>
}
