import ReactCountryFlag from "react-country-flag"
import {useEffect, useState} from "react";
import remarkGfm from 'remark-gfm'
import {ApiConsumer} from "api/ApiConsumer";
import Loader from "components/Loader";
import {CMS_Routes} from "routes/cms";
import {TeamDetail} from "components/Team/detail";

export default function Affiliates() {
    const [countries, setCountries] = useState([])
    const [loading, setLoading] = useState(true)
    const [detail, setDetail] = useState('')
    const [visible, setVisible] = useState(false)
    const [name, setName] = useState('')

    function compare(a, b) {
        return a.id - b.id
    }

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(CMS_Routes.CONTENT.RSVM)
            .then(res => setCountries(res.data.data))
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    return <>
        <TeamDetail detail={detail} name={name} visible={visible} setVisible={setVisible} image={null} />
        <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <svg
                    className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
                    width={500}
                    height={500}
                    fill="none"
                    viewBox="0 0 504 504"
                    role="img"
                    aria-labelledby="svg-workcation"
                >
                    <defs>
                        <pattern
                            id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"/>
                </svg>

                <div className="relative">
                    <div className='text-center'>
                        <p className="text-3xl font-extrabold tracking-tight primary sm:text-4xl">
                            AFI RVSM/PBCS State Approvals
                        </p>
                    </div>

                    <blockquote className="mt-3">
                        <div
                            className="max-w-3xl mx-auto text-center text-md leading-9 font-medium text-gray-900 text-secondary">
                            <p>
                                ARMA interacts with the ICAO WACAF and ESAF Regional Offices.
                            </p>
                        </div>
                        {
                            loading ? <Loader/> :
                                <div
                                    className="mt-10 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-cols-8 mb-10">
                                    {
                                        countries.sort(compare).map(country => {
                                            return <div key={country.attributes.code}
                                                        className="space-y-1 space-x-1 drop-shadow-2xl text-center text-primary cursor-pointer hover:border-2 border-2 border-transparent hover:border-blue-500"
                                                        onClick={() => {
                                                            setVisible(true)
                                                            setDetail(country.attributes.content)
                                                            setName(country.attributes.name)
                                                        }}>
                                                <ReactCountryFlag countryCode={country.attributes.code} className="emojiFlag"
                                                                  style={{fontSize: '3em', lineHeight: '1em'}}
                                                                  aria-label={country.attributes.name} svg={true} />
                                                <br/>{country.attributes.name}
                                            </div>
                                        })
                                    }
                                </div>
                        }
                    </blockquote>
                </div>
            </div>
        </section>
    </>
}
