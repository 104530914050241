import ReactMarkdown from "react-markdown";

export function MediaTable({media}) {
    function TypeColour(type) {
        if (type === 'Article')
            return 'bg-indigo-100 text-indigo-800'
        if (type === 'Video')
            return 'bg-pink-100 text-pink-800'
        if (type === 'Hot')
            return 'bg-red-300 text-red-900 animate-pulse'
        return 'bg-green-100 text-green-800'
    }

    return <div className="grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
        {media.map((post) => (
            <div>
                <div>
                  <span
                      className={`${TypeColour(post.attributes.type)} inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium`}
                  >
                    {post.attributes.type}
                  </span>
                </div>
                <div className="block mt-4">
                    <a key={post.attributes.title} href={post.attributes.link} target='_blank' rel="noopener"
                       className="text-xl font-semibold text-gray-900">{post.attributes.title}</a>
                    <div className="mt-3 text-base text-gray-500 prose max-w-none">
                        <ReactMarkdown>{post.attributes.content}</ReactMarkdown>
                    </div>
                </div>
                <div className="mt-6 flex items-center">
                    <div className="flex-shrink-0">
                        <>
                            <span className="sr-only">{post.attributes.source}</span>
                        </>
                    </div>
                    <div>
                        <p className="text-sm font-medium text-gray-900">
                            <a key={post.attributes.title} href={post.attributes.link} target='_blank' rel="noopener">{post.attributes.source}</a>
                        </p>
                        <div className="flex space-x-1 text-sm text-gray-500">
                            <time className='font-sm' dateTime={post.attributes.date}>{post.attributes.date}</time>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div>
}
