import PageTitle from "components/PageTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons";
import Breadcrumbs from "components/Breadcrumbs";
import {useState} from "react";
import {NavLink} from "react-router-dom";
import {ApiConsumer} from "api/ApiConsumer";
import {APIRoutes} from "routes/api";
import {GlobeIcon} from "@heroicons/react/outline";

export function FormF1() {
    const [crumbs] = useState([{name: 'Resources', href: '/resources', current: true},
        {name: 'Forms', href: '/resources', current: true}, {name: 'NPM Form', href: '/resources', current: true}])
    const [details, setDetails] = useState({'subject': 'NPM Form'})
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)

    function send() {
        setLoading(true)
        ApiConsumer.post(APIRoutes.API.FORM, details)
            .then(() => {
                setSent(true)
            })
            .catch(err => {
                setSent(false)
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    return <>
        <PageTitle title={'NPM Form'}/>
        <Breadcrumbs crumbs={crumbs}/>
        <div
            className="mt-8 max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6 mb-10">
            <div className="space-y-5 lg:col-start-1 lg:col-span-4  mb-4">

                <div className='border-2 pb-6 rounded mt-5 pt-5'>
                    <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="mt-6 prose max-w-none text-gray-500 mx-auto space-y-5">
                            <h2 className='text-secondary text-center text-2xl font-extrabold'>NPM FORM F1</h2>
                            <p className='text-center px-5'>Npm point of contact details/change of point of contact
                                details for matters relating to RVSM.
                            </p>
                            <div className="grid grid-cols-1 lg:grid-cols-3">
                                {/* Contact form */}
                                {
                                    sent ?
                                        <h5 className='tx-success mt-5 mb-5 text-center col-span-2 pt-5'>
                                            <strong>Submitted Successfully.</strong><br/>
                                            Thank You. Our friendly staff will review your Enquiry/Request and get back
                                            to you.
                                        </h5> :
                                        <div className="sm:px-10 lg:col-span-2 xl:p-2">
                                            <form method="POST" onSubmit={event => {
                                                event.preventDefault()
                                                send()
                                            }}
                                                  className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        State of Registry
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="state_of_registry"
                                                            id="state_of_registry"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['state_of_registry']: event.target.value
                                                            }))}
                                                            value={details?.state_of_registry}
                                                            maxLength={2}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Mandated By
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="mandated_by"
                                                            id="mandated_by"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['mandated_by']: event.target.value
                                                            }))}
                                                            value={details?.mandated_by}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="relative col-span-2">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                <span className="bg-white px-2 text-gray-500">
                                                  National Point of Contact
                                                </span>
                                                    </div>
                                                </div>


                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Full Name
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="full_name"
                                                            id="full_name"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['full_name']: event.target.value
                                                            }))}
                                                            value={details?.full_name}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Address
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="address"
                                                            id="address"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['address']: event.target.value
                                                            }))}
                                                            value={details?.address}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="relative col-span-2">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                <span className="bg-white px-2 text-gray-500">
                                                  <svg className="w-6 h-6" fill="none" stroke="currentColor"
                                                       viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                      <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
                                                  </svg>
                                                </span>
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Title
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            id="title"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['title']: event.target.value
                                                            }))}
                                                            value={details?.title}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Surname
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="surname"
                                                            id="surname"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['surname']: event.target.value
                                                            }))}
                                                            value={details?.surname}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Initials
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="initials"
                                                            id="initials"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['initials']: event.target.value
                                                            }))}
                                                            value={details?.initials}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Post/Position
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="position"
                                                            id="position"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['position']: event.target.value
                                                            }))}
                                                            value={details?.position}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Telephone
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="telephone"
                                                            id="telephone"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['telephone']: event.target.value
                                                            }))}
                                                            value={details?.telephone}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Fax
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="fax"
                                                            id="fax"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['fax']: event.target.value
                                                            }))}
                                                            value={details?.fax}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Email
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['email']: event.target.value
                                                            }))}
                                                            value={details?.email}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Mobile
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="mobile"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['mobile']: event.target.value
                                                            }))}
                                                            value={details?.mobile}
                                                        />
                                                    </div>
                                                </div>

                                                <div/>
                                                <div className="sm:col-span-2 sm:flex sm:justify-end">
                                                    <button
                                                        type="submit"
                                                        className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                                    >
                                                        Submit {loading ? <GlobeIcon
                                                            className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                                        <FontAwesomeIcon className='ml-2 pt-1 animate-bounce'
                                                                         icon={faArrowAltCircleRight}/>}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                }

                                {/* Contact information */}
                                <div className="relative overflow-hidden py-10 px-6 bg-primary sm:px-10 xl:p-12">
                                    <h3 className="text-lg font-medium text-white font-bold">Notes</h3>
                                    <hr className='bg-primary text-secondary'/>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>State of registry</strong>: Enter the two letter ICAO identifier as
                                        contained in ICAO Doc 7910.
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white ">
                                        <strong className='text-accent'>Name of Operator</strong>: Enter the operator’s
                                        3 letter ICAO
                                        identifier as contained in ICAO Doc 8585. For International General Aviation,
                                        enter “YYY” (write the name of the operator/ owner in the Remarks 11 field). For
                                        military aircraft, enter “MIL”.
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>State of Operator</strong>: Enter the two letter
                                        ICAO identifier as
                                        contained in ICAO Doc 7910
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Aircraft Series</strong>: Enter the two letter
                                        ICAO identifier as
                                        contained in ICAO Doc 7910
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Aircraft Type</strong>: Enter series of aircraft
                                        type or manufacturer’s
                                        customer designation, e.g., for Airbus A320‐211, enter 211; for Boeing B747‐438,
                                        enter 400 or 438
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Manufacturers Serial No</strong>: Enter aircraft
                                        serial number as given
                                        by manufacturer
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Registration No</strong>: Enter aircraft’s
                                        current registration number
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Mode S Address Code</strong>: Enter ICAO
                                        allocated Aircraft Mode S
                                        address code
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Date of Withdrawal of RVSM Approval</strong>:
                                        Enter date of withdrawal
                                        of RVSM approval
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Reason for Withdrawal of RVSM Approval</strong>:
                                        Enter the reason of
                                        withdrawal of RVSM approval
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Date RCP240/RSP180 Approval Withdrawn</strong>:
                                        Enter date of withdrawal
                                        of RCP240/RSP180/PBCS approval
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Date RCP240/RSP180 Approval Withdrawn</strong>:
                                        Enter the reason of
                                        withdrawal of RCP240/RSP180/PBCS approval
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Remarks</strong>: Fill in if necessary. Use a
                                        separate sheet of paper if
                                        insufficient space available
                                    </p>
                                    <hr className='mt-10 pt-5'/>
                                    <p className='text-accent'>
                                        For more information, please feel free to send us a message.
                                    </p>
                                    <NavLink to={'/contact'}>
                                        <p className='mt-3 font-bold text-accent'>
                                            Contact Us
                                            <FontAwesomeIcon className='animate-bounce ml-2 pt-1'
                                                             icon={faArrowAltCircleRight}/>
                                        </p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
