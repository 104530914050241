import {Disclosure} from '@headlessui/react'
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import WEBSITE_ROUTES from "routes/website";
import {NavLink} from "react-router-dom";
import logo from "assets/images/ARMA_Light Wordmark.svg"
import icao from "assets/images/ICAO LOGO White.webp"


export default function NavBar() {
    return (
        <>
            <Disclosure as="nav" className="bg-primary shadow-xl fixed w-full top-0 z-50 mb-6">
                {({open}) => (
                    <>
                        <header className="sticky top-0 z-50">
                            <div className="sticky top-0 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                                <div className="sticky relative flex justify-between h-16">
                                    <div className="sticky absolute inset-y-0 left-0 flex items-center sm:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button
                                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                            ) : (
                                                <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                    <div
                                        className="sticky flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                        <div className="flex-shrink-0 flex items-center">
                                            <img
                                                className="block lg:hidden h-8 w-auto"
                                                src={logo}
                                                alt="ARMA Logo"
                                            />
                                            <img
                                                className="hidden lg:block h-8 w-auto"
                                                src={logo}
                                                alt="ARMA"
                                            />
                                            <img
                                                className="block h-10 w-auto ml-2 border-l-2 pl-2"
                                                src={icao}
                                                alt="ARMA Logo"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="sticky absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                        <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                            {
                                                WEBSITE_ROUTES.map((route, index) => {
                                                    return <NavLink
                                                        key={index}
                                                        activeClassName={'border-b-2 font-bold text-accent'}
                                                        exact={route.exact}
                                                        className="b-accent text-white inline-flex items-center px-1 pt-1 text-sm font-medium hover:text-accent"
                                                        to={route.link}>{route.name}</NavLink>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Disclosure.Panel className="sm:hidden">
                                <div className="pt-2 pb-4 space-y-1">
                                    {
                                        WEBSITE_ROUTES.map(routes => {
                                            return <Disclosure.Button
                                                as="a"
                                                href={routes.link}
                                                className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                            >
                                                {routes.name}
                                            </Disclosure.Button>
                                        })
                                    }
                                </div>
                            </Disclosure.Panel>
                        </header>
                    </>
                )}
            </Disclosure>
        </>
    )
}
