import Banner from "components/Banner";
import Feature from "components/Feature";
import CTA from "components/CTA";
import Affiliates from "components/Affiliates";

export default function Home() {
    return <>
        <Banner/>
        <Feature/>
        <CTA/>
        <Affiliates/>
    </>
}
