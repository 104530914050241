import {
    CloudUploadIcon,
    LockClosedIcon,
    RefreshIcon,
    ShieldCheckIcon,
} from '@heroicons/react/outline'
import logo from "assets/images/ARMA_Logo3-22.svg";
import {useState} from "react";

const features = [
    {
        name: 'Height Monitoring',
        description: 'Aircraft height-keeping performance monitoring is a quality check on the engineering work and approval process associated with the safety of RVSM operations.',
        icon: CloudUploadIcon,
        entrance: "animate__animated animate__zoomIn"
    },
    {
        name: 'RVSM Approvals',
        description: 'Reduced Vertical Separation Minimum (RVSM) is defined as the reduction of vertical space between aircraft from 2,000 to 1,000 feet at flight levels from 29,000 feet up to 41,000 feet.',
        icon: LockClosedIcon,
        entrance: "animate__animated animate__zoomIn"
    },
    {
        name: 'Minimum Monitoring Requirements',
        description: 'Aircraft engineering work that is required for the aircraft to receive RVSM airworthiness approval must be completed prior to the aircraft being monitored.',
        icon: RefreshIcon,
        entrance: "animate__animated animate__zoomIn"
    },
    {
        name: 'Performance Based Communication Surveillance',
        description: 'The RMA is an APIRG authorised body to provide Safety Oversight services in connection with implementation and continued safe use of PBCS within designated airspace.',
        icon: ShieldCheckIcon,
        entrance: "animate__animated animate__zoomIn"
    }
]

export default function Feature() {
    const [visible, setVisible] = useState(false)
    return (
        <div className="relative bg-white py-3 sm:py-16 lg:py-24">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <p className="mt-2 text-3xl font-extrabold tracking-tight primary sm:text-4xl">
                    What We Do
                </p>
                <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                    ICAO/ARMA has the responsibilities, among others, to monitor aircraft height-keeping
                    performance and the occurrence of large height deviations, and to conduct safety
                    assessments for its affiliated ICAO contracting States and FIRs.
                </p>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
                        {features.map((feature) => (
                            <div key={feature.name}
                                 className={`pt-6 cursor-pointer ${feature.entrance}`}>
                                <div
                                    className="flow-root rounded-lg bg-gray-50 px-6 pb-8 shadow-2xl hover:drop-shadow-xl ease-in-out duration-300 transition hover:translate-y-1 border-2 hover:border-accent">
                                    <div className="-mt-6">
                                        <div>
                                          <span
                                              className="inline-flex items-center justify-center rounded-md bg-indigo-500 p-3 shadow-lg">
                                              <img src={logo} alt={''} width='40px'/>
                                          </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900 font-bold">{feature.name}</h3>
                                        <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
