import {useEffect, useState} from "react";
import Breadcrumbs from "components/Breadcrumbs";
import PageTitle from "components/PageTitle";
import ResourceGrid from "components/ResourceGrid";
import {useContentHook} from "hooks/contentHook";
import {CMS_Routes} from "routes/cms";

export default function Resources() {
    const [crumbs, setCrumbs] = useState([{name: 'Document', href: '/resources', current: true}])
    const [link, setLink] = useState('3')
    const [contentLoading, setContentLoading] = useState(true)
    const [sideLoading, setSideLoading] = useState(true)
    const [content, setContent] = useState('')
    const [title, setTitle] = useState('')
    const [sideMenu, setSideMenu] = useState([])

    let {loadSideMenu, loadContent, loadBreadCrumbs} = useContentHook()

    function loadMenu() {
        loadSideMenu(CMS_Routes.CONTENT.PAGE_MENU(3), [{attributes: {
            title: 'Resources',
            id: ''
        }}], setSideMenu, setSideLoading)
    }

    useEffect(() => {
        setContentLoading(true)
        loadContent('Resources', title, CMS_Routes.PAGES.RESOURCES, link, setContent, setTitle, setContentLoading, loadMenu)
    }, [link])

    return <>
        <PageTitle title={'Resources'}/>
        <Breadcrumbs crumbs={crumbs}/>
        <div
            className="max-w-7xl mx-auto px-4 py-10 sm:px-6 lg:px-8">
            {
                sideMenu.filter(x => x.attributes.id !== '').map(type => {
                    return <ResourceGrid type={type.id} title={type.attributes.title}/>
                })
            }
        </div>
    </>
}
