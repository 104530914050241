import Breadcrumbs from "components/Breadcrumbs";
import PageTitle from "components/PageTitle";
import {useEffect, useState} from "react";
import SideMenu from "components/SideMenu";
import PageLayout from "components/PageLayout";
import Circulars from "components/Circulars";
import {useContentHook} from "hooks/contentHook";
import {CMS_Routes} from "routes/cms";
import {PageContent} from "components/PageContent";

export default function Services() {
    const [crumbs, setCrumbs] = useState([{name: 'Our Services', href: '/services', current: true}])
    const [link, setLink] = useState('1')
    const [contentLoading, setContentLoading] = useState(true)
    const [sideLoading, setSideLoading] = useState(true)
    const [content, setContent] = useState('')
    const [title, setTitle] = useState('')
    const [sideMenu, setSideMenu] = useState([])

    const baseBreadCrumb = [{name: 'Our Services', href: '/services', current: true}]

    let {loadSideMenu, loadContent, loadBreadCrumbs} = useContentHook()

    function breadCrumbs(title) {
        loadBreadCrumbs(baseBreadCrumb, title, setCrumbs)
    }

    function loadMenu() {
        loadSideMenu(CMS_Routes.CONTENT.PAGE_MENU(2), [{
            attributes: {
                title: 'Our Services',
                id: ''
            }
        }], setSideMenu, setSideLoading)
    }

    useEffect(() => {
        setContentLoading(true)
        loadContent('Our Services', title, CMS_Routes.PAGES.SERVICES, link, setContent, setTitle, setContentLoading, loadMenu)
    }, [link])

    return <>
        <PageTitle title={'Our Services'}/>
        <Breadcrumbs crumbs={crumbs}/>
        <PageLayout content={<PageContent content={content?.content ?? ''} loading={contentLoading}/>}
                    side={<SideMenu menu={sideMenu} title={title} setTitle={setTitle}
                                    setCrumbs={breadCrumbs} loading={sideLoading} setContent={setLink}/>}/>
        <Circulars/>
    </>
}
