const BASE_URL = 'https://cms.arma.africa/api'
export const CMS_Routes = {
    PAGES: {
        PAGES: `${BASE_URL}/pages`,
        ABOUT: `${BASE_URL}/pages/1`,
        SERVICES: `${BASE_URL}/pages/2`,
        RESOURCES: `${BASE_URL}/pages/3`,
        POPI: `${BASE_URL}/pages/5`,
        TERMS: `${BASE_URL}/pages/4`,
        FAQS: `${BASE_URL}/faqs`,
        MEDIA: `${BASE_URL}/newsletters?_sort=created_at:DESC`,
        

    },
    RESOURCES: {
        RESOURCES: `${BASE_URL}/resources/?populate=*`,
        BY_TYPE: function (type_id) {
            return `${BASE_URL}/resources?filters[content][id][$eq]=${type_id}&populate=*`
        }
    },
    SERVICES: {
        SERVICES: `${BASE_URL}/services/`
    },
    CONTENT: {
        PAGE_MENU: function (page_id) {
            return `${BASE_URL}/contents?filters[page][id][$eq]=${page_id}`
        },
        PAGE_CONTENT: function (page_id) {
            return `${BASE_URL}/contents/${page_id}`
        },
        RSVM: `${BASE_URL}/rvsm-approvals`,
    },
    TEAM: {
        MEMBERS: `${BASE_URL}/teams?populate=*`
    }
}
