import {useEffect, useState} from "react";
import {Disclosure} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/outline'
import PageTitle from "components/PageTitle";
import Breadcrumbs from "components/Breadcrumbs";
import {ApiConsumer} from "api/ApiConsumer";
import {CMS_Routes} from "routes/cms";
import Circulars from "components/Circulars";
import Loader from "components/Loader";


export default function FAQ() {
    const [crumbs] = useState([{name: 'FAQs', href: '/faq', current: false}])
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(true)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    useEffect(() => {
        ApiConsumer.get(CMS_Routes.PAGES.FAQS)
            .then(res => setQuestions(res.data.data))
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }, [])
    return <>
        <PageTitle title={'FAQs'}/>
        <Breadcrumbs crumbs={crumbs}/>
        <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                {
                    loading ? <Loader/> : null
                }
                {!loading ? <div
                    className="max-w-full mx-auto mb-10">
                    <div className="bg-white">
                        <div
                            className="max-w-7xl mx-auto px-4 divide-y-2 divide-gray-200 sm:py-24 sm:px-6 lg:px-8 card border-2 rounded">
                            <div>
                                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
                                    {questions.map(faq => (
                                        <Disclosure as="div" key={faq.question}>
                                            {({open}) => (
                                                <>
                                                    <dt className="text-lg">
                                                        <Disclosure.Button
                                                            className="text-left w-full flex justify-between items-start text-gray-400">
                                                            <span
                                                                className="font-bold text-gray-900">{faq.attributes.question}</span>
                                                            <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                          />
                        </span>
                                                        </Disclosure.Button>
                                                    </dt>
                                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                        <p className="text-base text-gray-800">{faq.attributes.answer}</p>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    </div>
                </div> : null}
            </div>
        </div>
        <Circulars/>
    </>
}
