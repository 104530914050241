import PageTitle from "components/PageTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons";
import Breadcrumbs from "components/Breadcrumbs";
import {useState} from "react";
import {NavLink} from "react-router-dom";
import {ApiConsumer} from "api/ApiConsumer";
import {APIRoutes} from "routes/api";
import {GlobeIcon} from "@heroicons/react/outline";

export function FormF3() {
    const [crumbs] = useState([{name: 'Resources', href: '/resources', current: true},
        {name: 'Forms', href: '/resources', current: true}, {name: 'ARMA F3 – Withdrawal of RVSM', href: '/resources', current: true}])
    const [details, setDetails] = useState({'subject':'ARMA F3 – Withdrawal of RVSM'})
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)

    function send() {
        setLoading(true)
        ApiConsumer.post(APIRoutes.API.FORM, details)
            .then(() => {
                setSent(true)
            })
            .catch(err => {
                setSent(false)
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    return <>
        <PageTitle title={'ARMA F3 – Withdrawal of RVSM'}/>
        <Breadcrumbs crumbs={crumbs}/>
        <div
            className="mt-8 max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6 mb-10">
            <div className="space-y-5 lg:col-start-1 lg:col-span-4  mb-4">

                <div className='border-2 pb-6 rounded mt-5 pt-5'>
                    <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="mt-6 prose max-w-none text-gray-500 mx-auto space-y-5">
                            <h2 className='text-secondary text-center text-2xl font-extrabold'>WITHDRAWAL OF APPROVAL TO
                                OPERATE IN RMA RVSM
                                AIRSPACE</h2>
                            <p className='text-center px-5'>When a State of Registry has cause to withdraw the RVSM
                                approval of an operator/aircraft,
                                details as requested below, must be submitted to the ARMA by the most appropriate
                                method.</p>
                            <div className="grid grid-cols-1 lg:grid-cols-3">
                                {/* Contact form */}
                                {
                                    sent ?
                                        <h5 className='tx-success mt-5 mb-5 text-center col-span-2 pt-5'>
                                            <strong>Submitted Successfully.</strong><br/>
                                            Thank You. Our friendly staff will review your Enquiry/Request and get back
                                            to you.
                                        </h5> :
                                        <div className="sm:px-10 lg:col-span-2 xl:p-2">
                                            <form method="POST" onSubmit={event => {
                                                event.preventDefault()
                                                send()
                                            }}
                                                  className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        State of Registry
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="state_of_registry"
                                                            id="state_of_registry"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['state_of_registry']: event.target.value
                                                            }))}
                                                            value={details?.state_of_registry}
                                                            maxLength={2}
                                                        />
                                                    </div>
                                                </div>
                                                <div>

                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Name of Operator
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="name_of_registry"
                                                            id="name_of_registry"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['name_of_registry']: event.target.value
                                                            }))}
                                                            value={details?.name_of_registry}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        State of Operator
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="state_of_operator"
                                                            id="state_of_operator"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['state_of_operator']: event.target.value
                                                            }))}
                                                            value={details?.state_of_operator}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Aircraft Type
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="aircraft_type"
                                                            id="aircraft_type"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['aircraft_type']: event.target.value
                                                            }))}
                                                            value={details?.aircraft_type}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Aircraft Series
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="aircraft_series"
                                                            id="aircraft_series"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['aircraft_series']: event.target.value
                                                            }))}
                                                            value={details?.aircraft_series}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Manufacturers Serial No
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="manufacturers_serial_no"
                                                            id="manufacturers_serial_no"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['manufacturers_serial_no']: event.target.value
                                                            }))}
                                                            value={details?.manufacturers_serial_no}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Registration No
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="registration_no"
                                                            id="registration_no"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['registration_no']: event.target.value
                                                            }))}
                                                            value={details?.registration_no}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Mode S Address Code
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="mode_s_address_code"
                                                            id="mode_s_address_code"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['mode_s_address_code']: event.target.value
                                                            }))}
                                                            value={details?.mode_s_address_code}
                                                        />
                                                    </div>
                                                </div>
                                                <div/>
                                                <div className="relative col-span-2">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                <span className="bg-white px-2 text-gray-500">
                                                  <svg className="w-6 h-6" fill="none" stroke="currentColor"
                                                       viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                      <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
                                                  </svg>
                                                </span>
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Date of Withdrawal of RVSM Approval
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="date"
                                                            name="Date_of_withdrawal_of_rvsm_approval"
                                                            id="Date_of_withdrawal_of_rvsm_approval"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['Date_of_withdrawal_of_rvsm_approval']: event.target.value
                                                            }))}
                                                            value={details?.Date_of_withdrawal_of_rvsm_approval}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-span-2 x-span-2'>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Reason of Withdrawal of RVSM Approval
                                                    </label>
                                                    <div className="mt-1">
                                                <textarea
                                                    type="textarea"
                                                    name="reason_of_withdrawal_of_rvsm_approval"
                                                    id="reason_of_withdrawal_of_rvsm_approval"
                                                    disabled={loading}
                                                    className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                    onChange={event => setDetails(prevState => ({
                                                        ...prevState,
                                                        ['reason_of_withdrawal_of_rvsm_approval']: event.target.value
                                                    }))}
                                                    value={details?.reason_of_withdrawal_of_rvsm_approval}
                                                />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Date RCP240/RSP180 Approval Withdrawn
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="date"
                                                            name="date_rcp240"
                                                            id="date_rcp240"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['date_rcp240']: event.target.value
                                                            }))}
                                                            value={details?.date_rcp240}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-span-2 x-span-2'>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Reason for Withdrawal of RCP240/RSP180 Approval
                                                    </label>
                                                    <div className="mt-1">
                                                <textarea
                                                    type="textarea"
                                                    name="reason_of_withdrawal_of_rvsm_approval"
                                                    id="reason_of_withdrawal_of_rvsm_approval"
                                                    disabled={loading}
                                                    className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                    onChange={event => setDetails(prevState => ({
                                                        ...prevState,
                                                        ['reason_of_withdrawal_of_rcp240_approval']: event.target.value
                                                    }))}
                                                    value={details?.reason_of_withdrawal_of_rcp240_approval}
                                                />
                                                    </div>
                                                </div>

                                                <div className="relative col-span-2">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                <span className="bg-white px-2 text-gray-500">
                                                  <svg className="w-6 h-6" fill="none" stroke="currentColor"
                                                       viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                      <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
                                                  </svg>
                                                </span>
                                                    </div>
                                                </div>

                                                <div className='col-span-2 x-span-2'>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Remarks
                                                    </label>
                                                    <div className="mt-1">
                                                <textarea
                                                    type="textarea"
                                                    name="remarks"
                                                    id="remarks"
                                                    disabled={loading}
                                                    className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                    onChange={event => setDetails(prevState => ({
                                                        ...prevState,
                                                        ['remarks']: event.target.value
                                                    }))}
                                                    value={details?.remarks}
                                                />
                                                    </div>
                                                </div>
                                                <div className='col-span-2 x-span-2'>
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-900">
                                                        Email
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            disabled={loading}
                                                            className="py-3 px-4 block w-full text-gray-900 focus:ring-indigo-500 focus:border-gray-900 rounded-md border-indigo-200 border-2"
                                                            onChange={event => setDetails(prevState => ({
                                                                ...prevState,
                                                                ['email']: event.target.value
                                                            }))}
                                                            value={details?.email}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2 sm:flex sm:justify-end">
                                                    <button
                                                        type="submit"
                                                        className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                                    >
                                                        Submit {loading ? <GlobeIcon
                                                            className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                                        <FontAwesomeIcon className='ml-2 pt-1 animate-bounce'
                                                                         icon={faArrowAltCircleRight}/>}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                }

                                {/* Contact information */}
                                <div className="relative overflow-hidden py-10 px-6 bg-primary sm:px-10 xl:p-12">
                                    <h3 className="text-lg font-medium text-white font-bold">Notes</h3>
                                    <hr className='bg-primary text-secondary'/>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>State of registry</strong>: Enter the two letter ICAO identifier as
                                        contained in ICAO Doc 7910.
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white ">
                                        <strong className='text-accent'>Name of Operator</strong>: Enter the operator’s 3 letter ICAO
                                        identifier as contained in ICAO Doc 8585. For International General Aviation,
                                        enter “YYY” (write the name of the operator/ owner in the Remarks 11 field). For
                                        military aircraft, enter “MIL”.
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>State of Operator</strong>: Enter the two letter ICAO identifier as
                                        contained in ICAO Doc 7910
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Aircraft Series</strong>: Enter the two letter ICAO identifier as
                                        contained in ICAO Doc 7910
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Aircraft Type</strong>: Enter series of aircraft type or manufacturer’s
                                        customer designation, e.g., for Airbus A320‐211, enter 211; for Boeing B747‐438,
                                        enter 400 or 438
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Manufacturers Serial No</strong>: Enter aircraft serial number as given
                                        by manufacturer
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Registration No</strong>: Enter aircraft’s current registration number
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Mode S Address Code</strong>: Enter ICAO allocated Aircraft Mode S
                                        address code
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Date of Withdrawal of RVSM Approval</strong>: Enter date of withdrawal
                                        of RVSM approval
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Reason for Withdrawal of RVSM Approval</strong>: Enter the reason of
                                        withdrawal of RVSM approval
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Date RCP240/RSP180 Approval Withdrawn</strong>: Enter date of withdrawal
                                        of RCP240/RSP180/PBCS approval
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Date RCP240/RSP180 Approval Withdrawn</strong>: Enter the reason of
                                        withdrawal of RCP240/RSP180/PBCS approval
                                    </p>
                                    <p className="mt-4 max-w-3xl text-sm text-white">
                                        <strong className='text-accent'>Remarks</strong>: Fill in if necessary. Use a separate sheet of paper if
                                        insufficient space available
                                    </p>
                                    <hr className='mt-10 pt-5'/>
                                    <p className='text-accent'>
                                        For more information, please feel free to send us a message.
                                    </p>
                                    <NavLink to={'/contact'}>
                                        <p className='mt-3 font-bold text-accent'>
                                            Contact Us
                                            <FontAwesomeIcon className='animate-bounce ml-2 pt-1'
                                                             icon={faArrowAltCircleRight}/>
                                        </p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
