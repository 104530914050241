import {GlobeIcon} from "@heroicons/react/outline";

export default function Loader({light = false}) {
    return <>
        <div className="self-auto text-center place-content-center mt-5 pt-5 mb-5 pb-5">
            <section
                className={`hero container max-w-screen-lg mx-auto pb-10 ${light ? 'text-white' : 'text-secondary'}`}>
                <GlobeIcon className="animate-spin h-6 w-6 content-center text-center mx-auto"/>
                loading
            </section>
        </div>
    </>
}
