import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from './reportWebVitals';
import 'animate.css'
import './index.css'

Sentry.init({
    dsn: "https://4ae04c73a9f04c8da6471f96f4f5b151@o86758.ingest.sentry.io/6292259",
    integrations: [new BrowserTracing()],
    environment: "production",
    tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App tab="app"/>);

reportWebVitals();
